import React from 'react'

const Footer = () => {
    return (
        <>
            {/* <div className="clearfix"></div>

            <footer className="site-footer">
                <div className="footer-inner bg-white">
                    <div className="row">
                        <div className="col-sm-6">
                            Copyright &copy; Nirala Sangeet Sadan
                        </div>
                        <div className="col-sm-6 text-right">
                            Developed by <a href="https://hashedbit.com/" target='_blank'>HashedBit Innovations</a>
                        </div>
                    </div>
                </div>
            </footer> */}
        </>
    )
}

export default Footer
